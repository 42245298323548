<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable :table_state="table_state" :store_names="store_names" />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/wesSPLTransactionMonitoring.module";

export default {
  name: "WESSPLTransactionMonitoring",
  components: { DataTable },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getWESSPLTRMONITTableFilters",
      getTableState: "getWESSPLTRMONITTableState",
      getTableData: "getWESSPLTRMONITTableData",
      getTableHeaders: "getWESSPLTRMONITTableHeaders",
      getTableProperties: "getWESSPLTRMONITTableProperties",
      getTableExportUrl: "getWESSPLTRMONITExportUrl",
      getTablePermissions: "getWESSPLTRMONITTablePermissions",
      getUploadType: "getWESSPLTRMONITSUploadType",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
  },
  computed: {
    isDataLoaded: function() {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
